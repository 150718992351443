import styled from "styled-components"

export const ProjectCardWrapper = styled.div`
  max-width: 400px;
  @media (${({ theme }) => theme.media}) {
    max-width: 500px;
  }
  @media (${({ theme }) => theme.mediaLarge}) {
    max-width: 900px;
  }
`
export const ProjectCardImageSource = styled.div`
  margin-bottom: 0;
  width: 300px;
  border-radius: 20px;
  @media (${({ theme }) => theme.mediaMedium}) {
    width: 500px;
  }
  @media (${({ theme }) => theme.mediaLarge}) {
    width: 900px;
  }
`
export const ProjectCardTitle = styled.h2`
  color: inherit;
  text-align: center;
`
export const ProjectCardLink = styled.a`
  color: ${({ theme }) => theme.textColor};
`
export const ProjectCardStack = styled.p`
  color: inherit;
  text-align: center;
`
