import React from "react"
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons"
import Hero from "../content/intro.mdx"
import Layout from "../components/Layout"
import Header from "../components/Header"
import { css } from "styled-components"
//Services parts
import ServicesCard from "../components/ServicesCard"
import ServicesIntroMDX from "../content/servicesIntro.mdx"
import Service1MDX from "../content/service1.mdx"
import Service2MDX from "../content/service2.mdx"
import Service3MDX from "../content/service3.mdx"
import Service4MDX from "../content/service4.mdx"
//Tech parts
import TechCard from "../components/TechCard"
import TechIntro from "../content/technoIntro.mdx"
import ReactMDX from "../content/react.mdx"
import NodeMDX from "../content/node.mdx"
import GraphQLMDX from "../content/graphql.mdx"
import AutreMDX from "../content/autre.mdx"
//Projects parts
import ProjectCard from "../components/ProjectCard"
import OnDiscute from "../components/OnDiscute"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import BackgroundDividers from "../components/BackgroundDividers"
import BackgroundClouds from "../components/BackgroundClouds"
import BackgroundObjects from "../components/BackgroundObjects"
import BackgroundDeepLayer from "../components/BackgroundDeepLayer"

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Accueil" />
        <Parallax ref={ref => (this.parallax = ref)} pages={15}>
          <BackgroundDividers />
          <BackgroundDeepLayer />
          <BackgroundClouds />
          <BackgroundObjects />
          {/*
           * Page Content
           - Hero
           - Services
           - Technologies
           - Projets
           - On discute
           */}
          <ParallaxLayer
            offset={0}
            speed={0.1}
            onClick={() => this.parallax.scrollTo(1)}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
            `}
          >
            <Header />
            <div
              css={css`
                flex-grow: 1;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: center;
                align-self: center;
                max-width: 800px;
                padding: 0 10px 10px 10px;
                text-align: justify;
              `}
            >
              <Hero />
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={1}
            speed={0.1}
            onClick={() => this.parallax.scrollTo(2)}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
          >
            <div
              css={css`
                max-width: 800px;
              `}
            >
              <ServicesIntroMDX />
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(3)}
          >
            <ServicesCard image="image1">
              <Service1MDX />
            </ServicesCard>
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(4)}
          >
            <ServicesCard image="image1">
              <Service2MDX />
            </ServicesCard>
          </ParallaxLayer>
          <ParallaxLayer
            offset={4}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(5)}
          >
            <ServicesCard image="image1">
              <Service3MDX />
            </ServicesCard>
          </ParallaxLayer>
          <ParallaxLayer
            offset={5}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(6)}
          >
            <ServicesCard image="image1">
              <Service4MDX />
            </ServicesCard>
          </ParallaxLayer>
          <ParallaxLayer
            offset={6}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(7)}
          >
            <div
              css={css`
                text-align: center;
                max-width: 800px;
              `}
            >
              <TechIntro />
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={7}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(8)}
          >
            <div
              css={css`
                text-align: center;
                max-width: 800px;
              `}
            >
              <TechCard logo="node">
                <NodeMDX />
              </TechCard>
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={8}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(9)}
          >
            <div
              css={css`
                text-align: center;
                max-width: 800px;
              `}
            >
              <TechCard logo="react">
                <ReactMDX />
              </TechCard>
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={9}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(10)}
          >
            <div
              css={css`
                text-align: center;
                max-width: 800px;
              `}
            >
              <TechCard logo="graphql">
                <GraphQLMDX />
              </TechCard>
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={10}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(11)}
          >
            <div
              css={css`
                text-align: center;
                max-width: 800px;
              `}
            >
              <AutreMDX />
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={11}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(12)}
          >
            <ProjectCard
              image="plombier"
              title="Site vitrine plomberie"
              link="https://antoinecorreiaplomberie.fr"
              stack="Gatsby / React / Serverless"
              css={css`
                text-align: center;
                max-width: 500px;
              `}
            >
              <AutreMDX />
            </ProjectCard>
          </ParallaxLayer>
          <ParallaxLayer
            offset={12}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(13)}
          >
            <ProjectCard
              image="blog"
              title="Mon Blog"
              link="https://geeklecode.com"
              stack="Gatsby / React / Serverless"
              css={css`
                text-align: center;
                max-width: 500px;
              `}
            >
              <AutreMDX />
            </ProjectCard>
          </ParallaxLayer>
          <ParallaxLayer
            offset={13}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(14)}
          >
            <h2
              css={css`
                text-align: center;
              `}
            >
              Donnez le meilleur de la technologie à votre projet et vos
              utilisateurs !
            </h2>
          </ParallaxLayer>
          <ParallaxLayer
            offset={14}
            speed={-0}
            css={css`
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
              padding: 10px;
            `}
            onClick={() => this.parallax.scrollTo(0)}
          >
            <OnDiscute />
            <Footer />
          </ParallaxLayer>
        </Parallax>
      </Layout>
    )
  }
}
