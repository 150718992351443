import React from "react"
import { ParallaxLayer } from "react-spring/renderprops-addons"
import cloud from "../images/cloud-svgrepo-com.svg"

const BackgroundClouds = () => {
  return (
    <>
      <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud "
          style={{ display: "block", width: "20%", marginLeft: "55%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "15%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "75%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "80%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={3.3} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "30%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={4.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "75%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={5} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={5.4} speed={0.2} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={4.36} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "30%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "80%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={7} speed={0.8} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "55%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "15%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={7.75} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={7} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "75%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={7.6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "80%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={8.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={7} speed={0.8} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "55%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "15%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={7.75} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={8} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "75%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={8.6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "80%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={9.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={10} speed={0.8} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "55%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "15%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={10.75} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={10} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "75%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={10.6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "80%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={11.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={11} speed={0.8} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "55%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "15%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={11.75} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={11.25} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "95%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={11.6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "80%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={12.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={12} speed={0.8} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "55%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "15%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={12.75} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={12.3} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "75%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={12.6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "80%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={13.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={13} speed={0.8} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "55%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "15%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={13.75} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={13.4} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "75%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={13.6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "20%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={14.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={14} speed={0.8} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "85%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "15%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={14.75} speed={0.5} style={{ opacity: 0.1 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "40%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={14} speed={0.2} style={{ opacity: 0.2 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "10%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "73%" }}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={14.6} speed={-0.1} style={{ opacity: 0.4 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "60%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "25%", marginLeft: "70%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "10%", marginLeft: "80%" }}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={15.6} speed={0.4} style={{ opacity: 0.6 }}>
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "20%", marginLeft: "5%" }}
        />
        <img
          srcSet={cloud}
          alt="background cloud"
          style={{ display: "block", width: "15%", marginLeft: "45%" }}
        />
      </ParallaxLayer>
    </>
  )
}

export default BackgroundClouds
