import React from "react"
import { ParallaxLayer } from "react-spring/renderprops-addons"
import satellite2 from "../images/satellite2.svg"
import { css } from "styled-components"
import speedoMeter from "../images/browser-svgrepo-com-2.svg"
import macbook from "../images/macbook-svgrepo-com.svg"
import shop from "../images/online-shop-svgrepo-com.svg"
import server from "../images/server-svgrepo-com.svg"
import rocket from "../images/rocket-svgrepo-com.svg"
import asteroid from "../images/meteor-asteroid-svgrepo-com.svg"
import earth from "../images/planet-earth-svgrepo-com.svg"
import satellite1 from "../images/satellite-svgrepo-com.svg"
import deathStar from "../images/space-station-svgrepo-com.svg"
import tieFighter from "../images/space-ship-svgrepo-com-4.svg"
import comet from "../images/comet-svgrepo-com.svg"
import enterprise from "../images/spaceship-svgrepo-com-2.svg"
import { UpDown } from "../utils/animations"

const BackgroundObjects = () => {
  return (
    <>
      <ParallaxLayer
        offset={1.3}
        speed={-0.3}
        style={{ pointerEvents: "none" }}
      >
        <UpDown>
          <img
            srcSet={satellite1}
            style={{ width: "15%", marginLeft: "80%" }}
            alt="satellite floating in background"
          />
        </UpDown>
      </ParallaxLayer>

      <ParallaxLayer
        offset={2.3}
        speed={-0.4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <img
          srcSet={earth}
          style={{ width: "60%" }}
          alt="earth  floating in background"
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={4.6}
        speed={-0.4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <UpDown>
          <img
            srcSet={satellite2}
            alt="satellite 2  floating in background"
            css={css`
              width: 30%;
              transform: rotateZ(45deg);
              margin-right: 70%;
              margin-bottom: 20%;
            `}
          />
        </UpDown>
      </ParallaxLayer>
      <ParallaxLayer
        offset={2.2}
        speed={-0.1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <img
          srcSet={speedoMeter}
          alt="speedometer floating in background"
          css={css`
            width: 30%;
            margin-bottom: auto;
          `}
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={3.2}
        speed={-0.1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <img
          srcSet={macbook}
          alt="macbook  floating in background"
          css={css`
            width: 30%;
            margin-bottom: auto;
          `}
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={4.2}
        speed={-0.1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <img
          srcSet={shop}
          alt="eshop floating in background"
          css={css`
            width: 30%;
            margin-bottom: auto;
          `}
        />
      </ParallaxLayer>
      <ParallaxLayer
        offset={5.2}
        speed={-0.1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <img
          srcSet={server}
          alt="server floating in background"
          css={css`
            width: 30%;
            margin-bottom: auto;
          `}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={6.4} speed={-0.5} style={{ opacity: 0.8 }}>
        <UpDown>
          <img
            srcSet={rocket}
            style={{
              display: "block",
              width: "15%",
              marginRight: "10%",
              marginTop: "10%",
            }}
            alt="rocket floating in background"
          />
        </UpDown>
      </ParallaxLayer>
      <ParallaxLayer
        offset={6.8}
        speed={-0.4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <img
          srcSet={deathStar}
          style={{ width: "60%" }}
          alt="earth  floating in background"
        />
      </ParallaxLayer>
      <ParallaxLayer offset={7.4} speed={-0.2} style={{ opacity: 0.8 }}>
        <UpDown>
          <img
            srcSet={asteroid}
            alt="asteroid floating in background"
            style={{
              display: "block",
              width: "15%",
              marginLeft: "60%",
              marginTop: "10%",
            }}
          />
        </UpDown>
      </ParallaxLayer>
      <ParallaxLayer offset={9.2} speed={0.6} style={{ opacity: 0.8 }}>
        <UpDown>
          <img
            srcSet={tieFighter}
            alt="tie fighter floating in background"
            style={{
              display: "block",
              width: "10%",
              marginLeft: "10%",
              marginTop: "10%",
            }}
          />
        </UpDown>
      </ParallaxLayer>
      <ParallaxLayer offset={11} speed={-1.6} style={{ opacity: 0.8 }}>
        <img
          srcSet={comet}
          alt="moon with flag floating in background"
          css={css`
            display: block;
            width: 10%;
            margin-left: 76%;
            margin-bottom: 30%;
            transform: rotateZ(45deg);
          `}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={14.2} speed={-0.6} style={{ opacity: 0.8 }}>
        <UpDown>
          <img
            srcSet={enterprise}
            alt="star trek entreprise floating in background"
            style={{
              display: "block",
              width: "24%",
              marginLeft: "5%",
              marginTop: "10%",
            }}
          />
        </UpDown>
      </ParallaxLayer>
    </>
  )
}

export default BackgroundObjects
