import React from "react"
import nodeLogo from "../../images/nodejs-logo.svg"
import reactLogo from "../../images/react-logo.svg"
import graphQL from "../../images/graphql-logo.svg"
import * as S from "./style"

const TechCard = ({ logo, children }) => {
  let logoSrcSet
  switch (logo) {
    case "node":
      logoSrcSet = nodeLogo
      break
    case "react":
      logoSrcSet = reactLogo
      break
    case "graphql":
      logoSrcSet = graphQL
      break
    default:
      logoSrcSet = null
  }
  return (
    <S.CardWrapper>
      <S.CardHeader>
        <S.LogoSource srcSet={logoSrcSet} alt={logo} />
      </S.CardHeader>
      <S.CardBody>{children}</S.CardBody>
    </S.CardWrapper>
  )
}

export default TechCard
