import styled from "styled-components"

export const FlexContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`

export const SectionTitle = styled.h1`
  text-align: center;
  margin-bottom: 3em;
`
export const windowBackgroundContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto;
  height: auto;
`
export const WindowBackground = styled.img`
  display: block;
  width: 300px;
  height: auto;
  border-radius: 22px;
  box-shadow: ${({ theme }) => theme.boxShadowLevel1};
  @media (${({ theme }) => theme.mediaMedium}) {
    width: 450px;
  }
  @media (${({ theme }) => theme.mediaLarge}) {
    width: 600px;
  }
`

export const ContactButton = styled.button`
  position: absolute;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  background-color: #3a506b;
  color: ${({ theme }) => theme.textColor};
  padding: 0.5em;
  border: 3px solid ${({ theme }) => theme.textColor};
  border-radius: 50px;
  font-size: 1.3em;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  & :focus {
    outline: 0;
  }
  & :active {
    box-shadow: none;
    margin-top: 4px;
  }
`

export const Footer = styled.footer`
  margin-top: 1em;
`
