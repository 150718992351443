import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Intégration CMS (Headless)`}</h2>
    <p>{`J’intègre votre CMS favori comme `}<strong parentName="p">{`Wordpress`}</strong>{` ou `}<strong parentName="p">{`Drupal`}</strong>{` à un site  Gatsby ou Next.js pour vous offrir le `}<strong parentName="p">{`meilleur`}</strong>{` des deux mondes. Un site incroyablement `}<strong parentName="p">{`rapide`}</strong>{` et `}<strong parentName="p">{`fonctionnel`}</strong>{` et la `}<strong parentName="p">{`simplicité`}</strong>{` de gestion de votre contenu avec le CMS de votre choix. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      