import React from "react"
import speedoMeter from "../../images/browser-svgrepo-com-2.svg"
import macbook from "../../images/macbook-svgrepo-com.svg"
import shop from "../../images/online-shop-svgrepo-com.svg"
import server from "../../images/server-svgrepo-com.svg"
import * as S from "./style"

const ServicesCard = ({ image, children }) => {
  let imageSrcSet
  switch (image) {
    case "image1":
      imageSrcSet = speedoMeter
      break
    case "image2":
      imageSrcSet = macbook
      break
    case "image3":
      imageSrcSet = shop
      break
    case "image4":
      imageSrcSet = server
      break
    default:
      imageSrcSet = null
  }
  return (
    <S.CardContainer>
      {/*<S.CardImageBackground>
        <S.CardImageSource srcSet={imageSrcSet} alt={`browser logo ${image}`} />
      </S.CardImageBackground>*/}
      <S.CardBody>{children}</S.CardBody>
    </S.CardContainer>
  )
}

export default ServicesCard
