import React from "react"
import { ParallaxLayer } from "react-spring/renderprops-addons"
import { css } from "styled-components"
import starsDark from "../images/stars-dark.svg"

const BackgroundDividers = () => {
  return (
    <>
      <ParallaxLayer
        offset={1}
        speed={1}
        css={css`
          background: #000000; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #434343,
            #000000
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #434343,
            #000000
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={2}
        speed={1}
        css={css`
          background: #141e30; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #243b55,
            #141e30
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #243b55,
            #141e30
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={3}
        speed={1}
        css={css`
          background: #2980b9; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #2c3e50,
            #2980b9
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #2c3e50,
            #2980b9
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={4}
        speed={1}
        css={css`
          background: #536976; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #292e49,
            #536976
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #292e49,
            #536976
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={5}
        speed={1}
        css={css`
          background: #2b5876; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #4e4376,
            #2b5876
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #4e4376,
            #2b5876
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={6}
        speed={1}
        css={css`
          background: #4b6cb7; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #182848,
            #4b6cb7
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #182848,
            #4b6cb7
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={7}
        speed={1}
        css={css`
          background: #2c3e50; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #3498db,
            #2c3e50
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #3498db,
            #2c3e50
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={8}
        speed={1}
        css={css`
          background: #005c97; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #363795,
            #005c97
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #363795,
            #005c97
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={9}
        speed={1}
        css={css`
          background: #000428; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #004e92,
            #000428
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #004e92,
            #000428
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
          /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={10}
        speed={1}
        css={css`
          background: #000046; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #1cb5e0,
            #000046
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #1cb5e0,
            #000046
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={11}
        speed={1}
        css={css`
          background: #0f2027; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #2c5364,
            #203a43,
            #0f2027
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #2c5364,
            #203a43,
            #0f2027
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={12}
        speed={1}
        css={css`
          background: #1f1c2c; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #928dab,
            #1f1c2c
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #928dab,
            #1f1c2c
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={13}
        speed={1}
        css={css`
          background: #16222a; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #3a6073,
            #16222a
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #3a6073,
            #16222a
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />
      <ParallaxLayer
        offset={14}
        speed={1}
        css={css`
          background: #ff512f; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #f09819,
            #ff512f
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #f09819,
            #ff512f
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        `}
      />

      <ParallaxLayer
        offset={0}
        speed={0}
        factor={15}
        style={{
          backgroundImage: `url(${starsDark})`,
          backgroundSize: "auto",
          backgroundRepeat: "repeat",
        }}
      />
    </>
  )
}

export default BackgroundDividers
