import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Mes Services`}</h1>
    <p>{`Petit point définition avant de commencer. Je vais beaucoup vous parler de `}<strong parentName="p">{`JAMsatck`}</strong>{`. Sous ce terme nébuleux se cache une nouvelle façon de concevoir des sites internet et applications web dynamiques, ultrarapides et sécurisées. C’est une combinaison de `}<strong parentName="p">{`JavaScript`}</strong>{`, d’`}<strong parentName="p">{`APIs`}</strong>{` et d’un `}<strong parentName="p">{`Markup`}</strong>{` généré à l'avance et servi au client sans serveurs.`}</p>
    <p>{`Les avantages à utiliser cette technique sont considérables, mais pas toujours adaptés. Je travaille aussi avec d’autres méthodes modernes et `}<strong parentName="p">{`parfois plus efficaces`}</strong>{`. J’en discuterais avec vous avec plaisir. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      