import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Et beaucoup d'autres ! 🔥`}</h2>
    <p>{`Gatsby, Next.js, Express, Jest, Enzyme, Cypress, CSS, Sass, styled-components, emotion, React-Spring, Apollo, GraphQL-Yoga, Prisma, MongoDB, MySQL, Firebase, Passport.js, AWS, Netlify, JWT, …`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      