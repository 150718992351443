import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Node.js`}</h2>
    <p>{`Ce petit bonhomme peut faire tourner le plus costaud des back-end pour un gain de performances significatif. Sans compter la diminution des coûts d’infrastructures qui en résulte !
Netflix, Twitter, LinkedIn, Uber, PayPal, vous connaissez ? `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      