import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import * as S from "./style"
import { css } from "styled-components"
import { ExternalLinkAlt } from "styled-icons/fa-solid"

const ProjectCard = ({ image, title, stack, link }) => {
  const data = useStaticQuery(graphql`
    {
      plombier: file(relativePath: { eq: "site-plombier.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog: file(relativePath: { eq: "blog.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  let imageSrcSet
  switch (image) {
    case "plombier":
      imageSrcSet = data.plombier.childImageSharp.fluid
      break
    case "blog":
      imageSrcSet = data.blog.childImageSharp.fluid
      break
    default:
      imageSrcSet = null
  }
  return (
    <S.ProjectCardWrapper>
      <S.ProjectCardImageSource>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <Image
            fluid={imageSrcSet}
            alt={`projet ${image} portfolio`}
            css={css`
              border-radius: 20px;
              margin-bottom: 10px;
            `}
          />
        </a>
      </S.ProjectCardImageSource>
      <S.ProjectCardTitle>
        <S.ProjectCardLink
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          {title}
        </S.ProjectCardLink>
        <ExternalLinkAlt size={24} />
      </S.ProjectCardTitle>
      <S.ProjectCardStack>{stack}</S.ProjectCardStack>
    </S.ProjectCardWrapper>
  )
}

export default ProjectCard
