import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Bonjour, je suis Mathias.`}</h1>
    <p>{`Je suis un `}<strong parentName="p">{`développeur web full-stack freelance spécialisé en JavaScript`}</strong>{`, basé à Lyon, j'aime mettre les dernières technologies au service d’expériences incroyablement rapides, uniques et envoutantes et ainsi aider les entreprises à booster leur business.
Voilà ce que je fais tous les jours. Plutôt sympathique comme travail n’est-ce pas ?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      