import styled from "styled-components"

export const CardContainer = styled.section`
  width: 95%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  opacity: 0.89;
  @media (${({ theme }) => theme.mediaLarge}) {
    flex-flow: row nowrap;
    align-items: center;
    width: 90%;
  }
  @media (${({ theme }) => theme.mediaExtraLarge}) {
    width: 80%;
  }
`
export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
`
export const CardImageBackground = styled.div`
  background-color: ${({ theme }) => theme.serviceCircle};
  padding: 2em 2em;
  border-radius: 100%;
  transform: translateY(100px);
  width: 100px;
  @media (${({ theme }) => theme.mediaLarge}) {
    transform: translateX(4em);
    width: 200px;
  }
`
export const CardImageSource = styled.img`
  width: 300px;
  margin-bottom: 0;
`
export const CardBody = styled.div`
  padding: 10px;
  border-radius: 20px;
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.serviceBackground};
  box-shadow: ${({ theme }) => theme.boxShadowLevel1};
  text-align: justify;
  @media (${({ theme }) => theme.mediaLarge}) {
    padding: 1em;
  }
`
export const SectionTitle = styled.h1`
  text-align: center;
`
