import React from "react"
import { ParallaxLayer } from "react-spring/renderprops-addons"
import { css } from "styled-components"
import planet1 from "../images/venus-svgrepo-com.svg"
import moon from "../images/moon-svgrepo-com-2.svg"
import jupiter from "../images/jupiter-svgrepo-com.svg"
import neptune from "../images/neptune-svgrepo-com.svg"

const BackgroundDeepLayer = () => {
  return (
    <>
      <ParallaxLayer offset={8.4} speed={-0.65} style={{ opacity: 0.8 }}>
        <img
          srcSet={planet1}
          alt="planet venus floating in background"
          css={css`
            display: block;
            width: 25%;
            margin-left: 80%;
            margin-bottom: 30%;
          `}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={11} speed={-0.45} style={{ opacity: 0.8 }}>
        <img
          srcSet={moon}
          alt="moon with flag floating in background"
          css={css`
            display: block;
            width: 20%;
            margin-right: 80%;
            transform: rotateZ(-35deg);
          `}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={13.4} speed={-0.75} style={{ opacity: 0.9 }}>
        <img
          srcSet={jupiter}
          alt="planet jupiter floating in background"
          css={css`
            display: block;
            width: 20%;
            margin-left: 78%;
          `}
        />
      </ParallaxLayer>
      <ParallaxLayer offset={13.2} speed={-0.2} style={{ opacity: 0.9 }}>
        <img
          srcSet={neptune}
          alt="planet jupiter floating in background"
          css={css`
            display: block;
            width: 15%;
            margin-left: 10%;
          `}
        />
      </ParallaxLayer>
    </>
  )
}

export default BackgroundDeepLayer
