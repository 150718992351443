import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Conseils en architecture sans serveurs`}</h2>
    <p>{`Le futur du cloud est déjà là avec les architectures sans serveurs. Découvrez comment le JAMstack et cette architecture d'un nouveau genre vous permettent de `}<strong parentName="p">{`réduire vos coûts`}</strong>{` et de développer des sites et applications web toujours plus rapides, accessibles et riches en fonctionnalités. Sans la peine associée à la gestion d'infrastructures complexes de type data center.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      