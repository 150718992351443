import React from "react"
import * as S from "./style"
import windowSVG from "../../images/client-browser.svg"
import { Link } from "gatsby"

const OnDiscute = () => {
  return (
    <>
      <S.FlexContainer>
        <h2>Vous êtes prêt à booster votre business ?</h2>
        <S.windowBackgroundContainer>
          <S.WindowBackground
            srcSet={windowSVG}
            alt="mac os window svg drawing"
          />
          <Link to="/contact/">
            <S.ContactButton tabindex="0">On en discute ?</S.ContactButton>
          </Link>
        </S.windowBackgroundContainer>
      </S.FlexContainer>
    </>
  )
}

export default OnDiscute
