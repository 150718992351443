import styled from "styled-components"

export const CardWrapper = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.serviceBackground};
  border-radius: 30px;
  padding: 1em;
`
export const CardHeader = styled.div``
export const CardBody = styled.div`
  text-align: center;
`
export const LogoSource = styled.img`
  height: 90px;
  width: auto;
  margin-bottom: 0.3em;
  @@media (${({ theme }) => theme.mediaMedium}) {
    height: 100px;
    }
`
